import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { updateSearch } from 'bernie-core';
import { isVrbo } from 'src/common/stores/context-store-utils';
import { useExperiment } from '@shared-ui/experiment-context';
import { ResultsSubHeader as SharedUIResultsSubHeader } from '@shared-ui/lodging-property-listing';
import { ProductType, useProductType } from '@shared-ui/retail-product-type-providers';
import { isGridViewVariant } from '../grid-view/utils';
export const ResultsSubHeaderWrapper = inject('context', 'searchCriteria', 'analytics', 'experiment', 'searchResults')(observer(({
  context,
  analyticsSearch,
  searchInputs,
  criteriaChanged,
  localizedText,
  switchPositionOfToggleOnEPS,
  skeletonLayout,
  analytics,
  experiment,
  searchResults
}) => {
  const [inputs, setInputs] = useState(searchInputs);
  const {
    exposure: gridViewExperimentExposure
  } = useExperiment('SRP_Grid_view_web', false);
  const productType = useProductType();
  const history = useHistory();
  const location = useLocation();
  const isNonVrboLodging = productType === ProductType.Lodging && !isVrbo(context);
  const onChange = value => {
    analyticsSearch.withPublishingAnalyticsPayload(updateSearch, analytics, experiment)({
      history,
      location,
      newParams: {
        useRewards: String(value)
      },
      replace: true
    });
  };
  useEffect(() => {
    if (criteriaChanged) {
      setInputs(searchInputs);
    }
  }, [criteriaChanged]);
  const resultData = {
    summary: {
      resultMessages: searchResults === null || searchResults === void 0 ? void 0 : searchResults.resultMessages,
      resultsHeading: searchResults === null || searchResults === void 0 ? void 0 : searchResults.resultsHeading,
      loyaltyInfo: searchResults === null || searchResults === void 0 ? void 0 : searchResults.loyaltyMessagingModel
    }
  };
  return React.createElement(SharedUIResultsSubHeader, {
    data: resultData,
    loading: searchResults === null || searchResults === void 0 ? void 0 : searchResults.isLoading,
    propertySearchListingsPwa: searchResults === null || searchResults === void 0 ? void 0 : searchResults.propertySearchListings,
    inputs: inputs,
    localizedText: localizedText,
    onRewardsToggleChange: onChange,
    switchPositionOfToggleOnEPS: switchPositionOfToggleOnEPS,
    skeletonLayout: skeletonLayout,
    alignment: isGridViewVariant(gridViewExperimentExposure) ? 'left' : undefined,
    standardLinkFontWeight: isNonVrboLodging ? 'bold' : 'regular'
  });
}));
ResultsSubHeaderWrapper.displayName = 'ResultsSubHeaderWrapper';