import * as React from 'react';
import { EGDSPrimaryButton, EGDSTertiaryButton } from '@egds/react-core/button';
import { EGDSIcon } from '@egds/react-core/icons';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useExperiment } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { useGridViewContext } from '@shared-ui/lodging-property-listing';
import { ProductCardAdaptiveView } from 'stores/page-layout/page-layout-store-utils';
import { useStores } from 'stores/use-stores';
import { buildEgClickstreamDataForGridViewToggle, isGridViewVariant } from './utils';
const ButtonItem = ({
  l10n,
  onClick,
  toggled,
  iconName,
  name
}) => toggled ? React.createElement(EGDSPrimaryButton, {
  type: "button",
  id: `${name}-toggle`,
  "aria-label": l10n.formatText('gridView.toggle.selected.a11yLabel', name)
}, React.createElement(EGDSIcon, {
  name: iconName
})) : React.createElement(EGDSTertiaryButton, {
  type: "button",
  onClick: onClick,
  id: `${name}-toggle`,
  "aria-label": l10n.formatText('gridView.toggle.unselected.a11yLabel', name)
}, React.createElement(EGDSIcon, {
  name: iconName
}));
export const GridViewToggle = ({
  alignment = 'end'
}) => {
  const {
    multiItem,
    pageLayout,
    analyticsSearch
  } = useStores();
  const l10n = useLocalization();
  const track = useEgClickstream();
  const {
    isGridView,
    toggleGridView
  } = useGridViewContext();
  const {
    exposure
  } = useExperiment('SRP_Grid_view_web', false);
  /**
   * The Grid View (SRP_Grid_view_web|54458) MVP is a FE only feature.
   * So there is no schema/Gql change for toggle tracking event on client side.
   */
  const trackGridViewToggleState = toggleState => {
    track(Object.assign({}, buildEgClickstreamDataForGridViewToggle(toggleState, analyticsSearch === null || analyticsSearch === void 0 ? void 0 : analyticsSearch.searchId)));
  };
  const updateGridViewState = () => {
    toggleGridView === null || toggleGridView === void 0 ? void 0 : toggleGridView();
    pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.setProductCardAdaptiveViewType(isGridView ? ProductCardAdaptiveView.LIST : ProductCardAdaptiveView.GRID);
    trackGridViewToggleState(!isGridView);
  };
  return isGridViewVariant(exposure) && !(multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) ? React.createElement(EGDSLayoutFlex, {
    direction: "row",
    justifyContent: alignment,
    space: "one",
    alignItems: "center"
  }, React.createElement(EGDSLayoutFlexItem, null, React.createElement(ButtonItem, {
    l10n: l10n,
    onClick: updateGridViewState,
    toggled: !isGridView,
    iconName: "view_list",
    name: l10n.formatText('gridView.toggle.flavour.list')
  })), React.createElement(EGDSLayoutFlexItem, null, React.createElement(ButtonItem, {
    l10n: l10n,
    onClick: updateGridViewState,
    toggled: isGridView,
    iconName: "view_grid_large",
    name: l10n.formatText('gridView.toggle.flavour.grid')
  }))) : null;
};