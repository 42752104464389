/* eslint-disable camelcase */
export const buildEgClickstreamDataForGridViewToggle = (toggleState, searchId) => ({
  event: {
    event_name: toggleState ? 'grid_view.selected' : 'list_view.selected',
    event_type: 'Interaction',
    event_version: '1.0.0'
  },
  search_request: {
    search_id: searchId
  }
});
/* eslint-enable camelcase */
// TnL SRP_Grid_view_web will have two variants for AA test
// so variant 3 and 4 should behave the same as control
export const isGridViewVariant = exposure => (exposure === null || exposure === void 0 ? void 0 : exposure.bucket) === 1 || (exposure === null || exposure === void 0 ? void 0 : exposure.bucket) === 2;